.master-module-container {
    gap: 20px;
}
.master-module-container .edit-prof-header{
    margin-top: 20px;
    height: calc(100vh - 70px);
}
.master-module-container .rdt_Table {
    padding: 0  20px !important;
}
.master-module-container .resource-header {
    overflow-y: hidden;
    padding-bottom: 10px;
}
.master-module-container .resource-body {
    overflow-y: auto;
    height: calc(100vh - 160px);
}
.master-module-container div:has(> .rdt_Pagination) {
    margin: 0 20px !important;
}
@media (min-width: 1025px) and (max-width: 1199px){
    .master-module-container .container-listing {
    padding: 20px 0px;
}
}