
body .resource-header::-webkit-scrollbar {
    height: 3px;
}
.resource-body div::-webkit-scrollbar {
    height: 5px;
}
.main-content:has(> .hr-listing) {
    overflow: auto;
}
 .modal-content.preview .full-hw {
     height: 100% !important;
    width: 100% !important;
}
body .user-access-listing-resp .rdt_TableBody{
    padding: 0 10px;
}
@media(min-width:1025px ) and (max-width:1199px){
    body .main-container {
        grid-template-columns: 160px 1fr;
        gap: 10px;
    }
    body .sidebar-wrapper {
        width: 150px;
        grid-template-rows: 0.2fr 1fr 0.2fr;
        }
        body .logo svg {
            height: 100px;
            width: 100px;
        }
        body .header {
            padding-right: 20px;
    }
    body .sidebar {
        padding: 10px 0;
    }
    body .sidebar ul li {
        margin-bottom: 5px;
    }
    body .sidebar-wrapper {
        width: 150px;
    }
    body .main-heading {
        font-size: 20px;
    }
    body .header-join-exp{
        gap: 10px;
    }
    body .filter-section{
        gap: 2px;
    }
    body .download-sec, .action-btn {
        gap: 2px;
        margin-left: 5px;
    }
    body .download-sec::before, body .action-btn::before {
        left: -5px;
    }
    body .hr-listing .resource-header {
        overflow: auto;
        padding: 0;
        gap: 20px;
    }
    .container-listing {
        padding: 20px;
    }
    
    body .hr-listing .rdt_Table{
        padding: 0;
    }
    body .user-access-listing-resp{
        width: calc(100vw - 190px);
    }
    body .hr-listing .hr-listing-body {
        width: 100% ;
    }
    div:has(> .rdt_Pagination) {
        margin: 0 0 0 20px !important;
    }
    .hr-listing :has(> .rdt_Pagination) {
        margin: 0 !important;
    }
    body .hr-listing-body div::-webkit-scrollbar {
        height: 5px;
        padding-left: 20px;
        margin-left: 50px;
    }
    
    body .hr-listing-body div::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        margin-left: 20px;
    }
    .personal-sec .prof-img {
        height: 50px !important;
        width: 50px !important;
    }
    .personal-sec .family-cart-wrapper {
        gap: 10px;
    }
    body .access_panel.resource-body {
        width: 92% ;
    }
    .modal-content.preview .full-hw {
        height: 100% !important;
        width: 100% !important;
    }
    body .resource-header {
        padding-bottom: 5px !important;
    }
}

@media(min-width:1200px ) and (max-width:1269px){
    body .resource-header {
        width: calc(100% - 20px);
        overflow: auto;
        gap: 30px;
        padding-bottom: 5px;
    }
}
@media(min-width:1200px ){
    body .user-access-listing-resp{
        width: calc(100vw - 230px);
    }
    body .hr-listing .hr-listing-body {
        width: calc(100% - 10px);
    }
     div:has(> .rdt_Pagination) {
         margin: 0 0 0 20px !important;
        }
        body .hr-listing-body div::-webkit-scrollbar {
            height: 5px;
            padding-left: 20px;
            margin-left: 50px;
        }
    body .resource-header::-webkit-scrollbar-track,
    body .user-access-listing-resp div::-webkit-scrollbar-track{
        padding-left: 20px;
        margin-left: 20px;
    }
    body .hr-listing-body div::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        margin-left: 20px;
    }
    body .container-listing .rdt_Table {
        padding: 0 0 0 20px;
    }
    body .master-module .rdt_Table {
        padding: 0 20px 0 20px;
    }
}
@media(min-width:1200px ) and (max-width:1700px){
    body .user-access-listing-resp{
        width: calc(100vw - 230px);
    }
    /* body .user-access-listing-resp .rdt_TableBody{
        padding: 0 10px;
    } */
    body .hr-listing .hr-listing-body {
        width: calc(100% - 10px);
    }
     div:has(> .rdt_Pagination) {
         margin: 0 0 0 20px !important;
        }
        body .hr-listing-body div::-webkit-scrollbar {
            height: 5px;
            padding-left: 20px;
            margin-left: 50px;
        }
    body .resource-header::-webkit-scrollbar-track,
    body .user-access-listing-resp div::-webkit-scrollbar-track{
        padding-left: 20px;
        margin-left: 20px;
    }
    body .hr-listing-body div::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        margin-left: 20px;
    }
    body .container-listing .rdt_Table {
        padding: 0 0 0 10px;
    }
    .hr-listing-body .rdt_TableBody{
        padding: 0 10px;
    }
    body .master-module .rdt_Table {
        padding: 0 20px 0 20px;
    }
    /* .hr-listing .rdt_TableCell:nth-child(4){
        min-width: 12%;
        max-width: 12%;
    }
    .hr-listing .rdt_TableCell:last-child{
        min-width: 13%;
        max-width: 13%;
    } */
    .personal-sec .prof-img {
        height: 60px !important;
        width: 60px !important;
    }
    .personal-sec .col-lg-4{
        width: 33.333%;
    }
    body .access_panel.resource-body {
        width: 92% ;
    }
     .rdt_Table{
        overflow-x: hidden;
    }
.modal-content.preview .full-hw {
    height: 100% !important;
    width: 100% !important;
}
.hr-listing div[data-column-id="3"], .hr-listing div[data-column-id="3"] {
    width: auto;
    min-width: 50px;
    max-width: 150px !important;
}
.hr-listing div[data-column-id="4"], .hr-listing div[data-column-id="4"] {
    width: auto;
    min-width: 50px;
    max-width: 130px !important;
}
.hr-listing div[data-column-id="7"], .hr-listing div[data-column-id="7"] {
    width: auto;
    min-width: 50px;
    max-width: 130px !important;
}
}
/* datatable hr listhing */
.hr-listing div[data-column-id="3"],.hr-listing div[data-column-id="3"] {
    width: auto;
    min-width: 50px;
    max-width: 350px;
}
.hr-listing div[data-column-id="4"],.hr-listing div[data-column-id="4"] {
    width: auto;
    min-width: 50px;
    max-width: 220px;
}
.hr-listing div[data-column-id="9"],.hr-listing div[data-column-id="9"] {
    width: auto;
    min-width: 50px;
    max-width: 110px;
}
.hr-listing div[data-column-id="8"] {
    width: 300px;
    min-width: 100px;
    max-width: 110px;
}
@media screen and (min-width: 1400px) {
    .hr-listing div[data-column-id="5"],.hr-listing div[data-column-id="6"],.hr-listing div[data-column-id="7"] {
        width: 100%;
        min-width: 50px;
        max-width: 100px;
    }
    .hr-listing div[data-column-id="3"], .hr-listing div[data-column-id="3"] {
        width: auto;
        min-width: 50px;
        max-width: 350px !important;
    }
    body .container-listing .rdt_Table {
        padding: 0 0 0 10px;
    }
    .hr-listing-body .rdt_TableBody{
        padding: 0 10px;
    }
}
@media screen and (min-width: 1600px) {
    .hr-listing div[data-column-id="5"],.hr-listing div[data-column-id="6"],.hr-listing div[data-column-id="7"] {
        width: 100%;
        min-width: 140px;
        max-width: 140px;
    }
    /* .hr-listing div[data-column-id="8"] {
        width: 180px;
        min-width: 180px;
        max-width: 180px;
    } */
}
@media screen and (min-width: 1900px) {
    .hr-listing div[data-column-id="5"],.hr-listing div[data-column-id="6"],.hr-listing div[data-column-id="7"] {
        width: 230px;
        min-width: 230px;
        max-width: 230px;
    }
    .hr-listing div[data-column-id="3"], .hr-listing div[data-column-id="3"] {
        width: auto;
        min-width: 50px;
        max-width: 400px !important;
    }
    /* .hr-listing div[data-column-id="8"] {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
    } */
}
body .empty-div{
    height: calc(100vh - 380px) !important;
}  