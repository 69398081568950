.header{
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    gap: 20px;
    width: calc(100% - 220px);
    background-color: #fff;
    height: 60px;
    padding-right: 20px;
    z-index: 999;
}
.header-dt{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    justify-self: flex-start;
    padding-left: 30px;
    white-space: nowrap;
}
.header-dt .dt-ukhra{
    color: var(--color-primary);
}
.access-panel{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: fit-content;
    padding: 0 15px;
    background-color: var(--bg-gray);
    border-radius: 50px;
    white-space: nowrap;
    border: 1px solid transparent;
}
.access-panel:hover{
    border-color: var(--color-primary);
}
.access-panel.active{
    background-color: var(--color-primary);
    color: #fff;
}
.access-panel.active button{
    color: #fff;
}
.header-profile-sec{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}
.header-profile-sec .prof-img{
    height: 40px !important;
    width: 40px !important;
}
.user-role
{
    background-color: var(--color-primary-light);
    width: fit-content;
    padding: 0 10px;
    color: var(--color-primary);
    border-radius: 25px;
    width: 100%;
}
.header-user-name{
    width: 100px;
}
.user-info{
    min-width: 100px;
}
@media (width <= 1200px) {
    .header{
        width: calc(100% - 170px);
        background-color: #fff;
    }
    
}