*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Quicksand;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #ac1c30;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-track:hover {
  background-color: #ccc;
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

:root {
  --color-primary: #ac1c30;
  --color-primary-light: #eac6cb;
  --bg-gray: #f8f8f8;
  --text-gray-color: #898989;
  --black: #000;
  --radius: 30px;
  --bg-pending: #f5a623;
  --bg-inactive: #d77748;
  --bg-active: #0ea759;
  --bg-admin: #44b678;
  --bg-user: #5999f7;
  --bg-status-box: #f6e8ea;
}

:focus-visible {
  outline: none;
}

input:-webkit-autofill:focus,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled {
  cursor: not-allowed;
}

@font-face {
  font-display: swap;
  font-family: 'Kanz-al-Marjaan';
  src: url("../fonts/Kanz-al-Marjaan.ttf");

}

@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("../fonts/Quicksand-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("../fonts/Quicksand-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("../fonts/Quicksand-Regular.ttf");
  font-weight: 600;
}

@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("../fonts/Quicksand-SemiBold.ttf");
  font-weight: 700;
}

@font-face {
  font-display: swap;
  font-family: Quicksand;
  src: url("../fonts/Quicksand-Bold.ttf");
  font-weight: 800;
}

/* to set font arabic */
.font-arabic {
  font-family: 'Kanz-al-Marjaan' !important;
  font-weight: 600;
}

/* layout styling  */
.container-listing {
  background-color: var(--bg-gray);
  border-radius: var(--radius) 0 0 0;
  padding: 20px 0;
  height: calc(100% - 20px);
  margin-top: 20px;
}

.horizontal-p-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.full-container {
  min-height: calc(100vh - 85px);
  margin: auto;
  margin-right: 20px;
  margin-top: 20px;
  height: calc(100% - 20px);
  background-color: var(--bg-gray);
  border-radius: var(--radius);
}

.profile-container {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 0.3fr 1fr;
  gap: 20px;
  padding-right: 20px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 13px;
}

input:focus {
  outline: 0;
}

p,
span,
div,
label,
a {
  text-decoration: none !important;
  font-size: 13px;
}

p {
  margin-bottom: 0 !important;
}

.main-heading {
  color: var(--color-primary);
  font-size: 22px;
  margin: 0;
}

/* action icon */
.action-icon {
  background-color: var(--bg-gray);
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  margin-right: 5px;
}

.action-icon a {
  font-size: 12px;
}

.action-icon:hover {
  border-color: var(--color-primary);
}

.border-hover {
  border: 1px solid transparent;

}

.border-hover:hover {
  border-color: var(--color-primary);
}

/* datatable style  */
.ejvslR {
  background-color: transparent !important;
  height: 50px !important;
  display: block !important;
  /* padding: 0 !important; */
}

/* .rdt_TableCell:not(.rdt_TableCell:nth-last-child(2),
  .rdt_TableCell:nth-last-child(1))::after {
  content: "";
  height: 50%;
  width: 2px;
  position: absolute;
  right: 10px;
  background: #f8f8f8;
} */

/* chekcbox */
/* .chekcbox:has(> .form-check-input:checked){
  border: none;
} */
body .form-check-input:focus {
  border-color: #a71d30;
  box-shadow: none;
}

body .form-check-input {
  margin-top: 0;
  cursor: pointer;
}

body .form-check-input:checked {
  background-color: #a71d30;
  border-color: #a71d30;
}

body .form-check-label {
  width: fit-content;
  cursor: pointer;
}

label {
  /* white-space: nowrap; */
  color: var(--text-gray-color);
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-nowrap {
  white-space: nowrap;
}

/* input checkbox rounded */
.input-check[type=checkbox] {
  border-radius: 50% !important;
  height: 15px;
  width: 15px;
}

.input-check:checked[type=checkbox] {
  --bs-form-check-bg-image: none !important;
  
}
/* 
label::selection {
  user-select: none;
} */
.Toastify {
  z-index: 9999999 !important;
  position: relative !important;
}
.disable_form_fields :is(input, select, textarea, .user-access-dropdown, .date-wrapper){
  pointer-events: none;
}
.disable_form_fields div:has(> :is(input, select, textarea, .user-access-dropdown, .date-wrapper)){
  cursor: not-allowed;
}
.disable_form_fields .emp_wrapper :is(label){
  cursor: not-allowed !important;
}
.disable_form_fields label:not(.emp_wrapper label) {
  display: block;
}
.disable_form_fields :is(.button-primary, [type="file"]){
  display: none;
}
.main-heading{
  position: relative;
  border-bottom: 2px solid var(--color-primary);
}
.main-heading::after{
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  background: url(../images/icons/heading-befor.svg);
  background-repeat: no-repeat;
  bottom: -10px;
  right: -19px;
}
.heading-both{
  position: relative;
}
.heading-both::before{
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  background: url(../images/icons/heading-befor.svg);
  background-repeat: no-repeat;
  bottom: -10px;
  left: -19px;
  transform: rotateY(180deg);
}