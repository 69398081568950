/* Popup.css */
.policy-main-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.policy-body {
    background-color: white;
    width: clamp(100px, 100%, 60vw);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.policy-header {
    width: 100% ;
    padding: 20px;
}
.policy-header h2{
    color: var(--color-primary);
    width: fit-content;
}

.policy-list {
    list-style: none;
    padding: 0 20px;
    height: 60vh;
    line-height: 1.5;
    overflow-y: scroll;
}
.policy-list li{
    margin: 20px 0;
    
}
.policy-checkbox-label {
    font-size: 14px;
}

.policy-checkbox-label span {
    color: var(--color-primary);
    font-size: 14px;
}

.policy-footer{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}