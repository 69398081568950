.resource-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.filter-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.action-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.download-sec,
.action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    margin-left: 10px;
}

.download-sec::before,
.action-btn::before {
    content: '';
    position: absolute;
    left: -9px;
    height: 50%;
    width: 2px;
    background-color: #f0f0f0;

}

/* user access listing dropdown  */
.user-access-dropdown .css-1fdsijx-ValueContainer {
    flex: unset !important;
    /* text-transform: capitalize !important; */
}

/* modal  */
.human-resources-modal .modal-body {
    max-height: 500px;
    overflow-y: auto;
}

.search-field .user-access-dropdown {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.access_panel.resource-body {
    width: 80%;
    margin: auto;
}

.module_names {
    text-align: right;
    padding-right: 30px;
}

.access_panel .row {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;
}

.body_wrapper .permission_details {
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 0px;
}

.checkbox {
    position: relative;
    top: 3px;
    left: 5px;
}

input[type=checkbox] {
    position: relative;
    border: 1px solid #898989;
    border-radius: 100%;
    cursor: pointer;
    height: 20px;
    width: 20px;
    -webkit-appearance: none;
    opacity: 1;
}

input[type=checkbox]:checked {
    background-color: #44b678;
    border-color: #44b678;
    opacity: 1;
}

input[type=checkbox]:before {
    content: '';
    position: absolute;
    right: 40%;
    opacity: 0;
    top: 48%;
    width: 5px;
    height: 9px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translate(-50%, -50%);
}

input[type=checkbox]:checked:before {
    opacity: 1;
}

.heading_wrapper input[type=checkbox] {
    height: 15px;
    width: 15px;
}

.heading_wrapper input[type=checkbox]:before {
    top: 46%;
    width: 4px;
    height: 7px;
}

.body_wrapper .module_names {
    font-weight: 700;
}

.access_panel .col {
    text-align: center;
}

.title-uppercase {
    /* font-size: 14px; */
    font-weight: 500;
    text-transform: capitalize;
}

.checkbox[disabled] {
    /* font-size: 14px; */
    background-color: rgba(211, 211, 211, 0.325);
    /* border: none; */
    cursor: not-allowed;


}