/* golbal style for pdf design  */

header.pdf-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--content-padding);
  background-color: var(--bg-color);
  color: #fff;
  font-size: 15px;
}

/* Define the content inside the header */
header .pdf-logo-sec {
  margin-right: 20px;
}

header .pdf-logo-sec img {
  height: 70px;
  width: 70px;
}

header .address-sec p {
  margin: 0;
}

/* Styling for the content in header */
.pdf-header-content {
  margin: 0;
}

.pdf-wrapper {
  position: relative;
  z-index: 9;
}

.pdf-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-image: url("./img/loginlogo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.1;
  z-index: -1;
}

.family-cart-wrapper,
table.table-info {
  z-index: 9;
  background-color: #fff;
  position: relative;
}

.pdf-wrapper p {
  margin-bottom: 5px;
  font-size: 14px;
}

.content-pair span {
  font-size: 14px;
  font-weight: 700;
}

span.info-label {
  color: var(--color-primary);
  margin-right: 5px;
}

.section-top-space {
  margin-top: 20px;
}
.pdf-wrapper .info-group-name,
.pdf-wrapper .info-group {
  display: flex;
  align-items: center;
  gap: 2px;
  font-weight: 700;
}
.container-rounded {
  height: calc(100% - 20px);
  margin: 20px 20px 0 0;
  background-color: var(--bg-gray);
  border-radius: var(--radius-pdf);
  padding: 20px;
}

.pdf-heading {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
  margin-left: -20px;
  width: fit-content;
  padding-bottom: 5px;
  font-size: 18px;
  position: relative;
}

.pdf-heading span {
  width: calc(100% );
  position: relative;
  display: inline-block;
  font-size: 16px;
}


.pdf-wrapper {
  background-color: #fff;
  --content-padding: 20px;
  --bg-color: #eec7b8;
  --radius-pdf: 20px;
}

.pdf-topbar {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--content-padding);
}

.address-sec {
  width: 330px;
  font-weight: 700;
}

.pdf-wrapper .arabic-name {
  font-size: 18px;
  color: var(--color-primary);
}

.personal-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.contact-sec {
  margin-top: 5px;
}


.pdf-prof-sec {
  background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--content-padding);
}

.pdf-body-two-col {
  display: flex;
  padding: var(--content-padding);
}

.pdf-sidebar-container {
  margin-right: 20px;
}

.pdf-sidebar-container .common-bg-box {
  width: 280px;
  background-color: #dbeff6;
  border-radius: var(--radius-pdf);
  margin-bottom: 20px;
  padding: 15px;
}
.deggree-box tr:last-child{
  position: relative;
}
.deggree-box tr:last-child::after{
  position: absolute;
  content: '';
  width: 90%;
  height: 1px;
  background-color: silver;
  bottom: 5px;
  left: 5%;
  
}

.content-pair-group .content-pair:not(:last-child) {
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 5px !important;
  padding-bottom: 5px;
}

.deggree-box * {
  border: none;
  border-style: none;
}

.deggree-box table {
  width: 100%;
  margin-top: 30px;
}

.deggree-box table tr {
  background-color: #fff;
}

.deggree-box tr td:first-child {
  border-left-style: none;
  position: relative;
}

.deggree-box tr td:first-child::after {
  content: "";
  height: 50%;
  width: 1px;
  background-color: silver;
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
}

.degree-header td {
  background-color: var(--bg-color);
  border: none !important;
}

.body-right-info {
  width: 100%;
}

.family-info-sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 30px 0 15px;
}

.prof-img {
  height: 70px !important;
  width: 70px !important;
}

.family-cart-wrapper {
  width: 100%;
}

table.table-info {
  width: 100%;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

td {
  border: 1px solid #eec7b8;
  border-style: none solid solid none;
  padding: 10px 20px;
}

tr:first-child td:first-child {
  border-top-left-radius: var(--radius-pdf);
}

tr:first-child td:last-child {
  border-top-right-radius: var(--radius-pdf);
}

tr:last-child td:first-child {
  border-bottom-left-radius: var(--radius-pdf);
}

tr:last-child td:last-child {
  border-bottom-right-radius: var(--radius-pdf);
}

tr:first-child td {
  border-top-style: solid;
}

tr td:first-child {
  border-left-style: solid;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.py-15 {
  padding: 15px 20px;
}

.pdf-body-full {
  width: 100%;
  padding: 10px;
}

.table-skillset {
  width: 70%;
  margin: auto;
}
.table-skillset td{
  width: 33.33%;
}
.table-mawze td{
  width: 25%;
}

.table-head-highlight {
  background-color: var(--bg-color);
}

.table-head-highlight td {
  padding: 5px 20px;
  text-align: center;
}

.pdf-body-full .table-skillset tr,
.pdf-body-full .table-mawze tr {
  text-align: center;
}

.pdf-body-full .table-skillset td:not(:last-child),
.pdf-body-full .table-mawze td:not(:last-child) {
  border-right: 1px solid #5b5b5b;
}
table.table-govt,
table.table-bank{
  width: 70%;
  margin: auto;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .pdf-heading span::after,
  .pdf-heading span::before {
    bottom: -9.31px;
  }

  .pdf-body-two-col {
    margin-top: 170px;
    position: relative;
  }

  div.pdf-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px;
    font-size: 15px;
    page-break-after: always;
  }

  @page {
    counter-increment: page;
    counter-reset: page 1;
    /* Start the counter from 1 */
  }



  @page {

    /* margin-top: 90px; */
  }

  body::after {
    content: "Page " counter(page);
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 10px;
  }

  /* Rest of your print-specific styles */
  body {
    -webkit-print-color-adjust: exact;
    /* print-color-adjust: exact; */
  }

  .pdf-wrapper::after {
    top: 25%;
    left: 60%;

  }

}