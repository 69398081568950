.main-container {
  min-height: 100vh;
  width: 100%;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
}
.sidebar-container {
  height: 100vh;
  width: 100%;
}
.content-cotainer {
  display: grid;
  grid-template-rows: 50px 1fr;
}
.main-header {
  height: 100%;
}
.main-content {
  height: 100%;
}
.sidebar-wrapper {
  width: 200px;
  position: fixed;
  display: grid;
  grid-template-rows: 0.2fr 1fr 0.2fr;
  padding: 20px 0;
  gap: 20px;
  height: 100%;
}
.logo {
  padding-left: 25px;
}
.sidebar-logo {
  width: 100px;
  fill: var(--primary-color);
}
.logo svg {
  height: 130px;
  width: 130px;
}

.sidebar {
  background-color: var(--bg-gray);
  border-radius: 0 30px 30px 0;
  overflow: hidden;
  padding: 20px 0;
  overflow-y: auto;
  min-height: 200px;
  width: 100%;
}
.sidebar-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding-left: 25px;
  text-align: center;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}
.sidebar ul li {
  margin-bottom: 10px;
}
.sidebar-menu li * {
  color: #898989;
  fill: #898989;
}
.sidebar-menu li a:hover,
.sidebar-menu li a:hover *,
.sidebar-menu li .active,
.sidebar-menu li .active * {
  background-color: transparent;
  color: var(--color-primary);
  fill: var(--color-primary);
  stroke: var(--color-primary);
}
.sidebar-menu * {
  color: var(--text-gray-color);
  font-weight: 700;
}
.sidebar-icon {
  height: 15px;
  width: 15px;
}
.ps-menu-icon {
  margin-right: 0 !important;
}
.ps-menu-button {
  height: 30px !important;
}

/* logout popup  */
.swal2-confirm,
.swal2-cancel {
  width: 100px;
  border-radius: 50px !important;
}
.swal2-confirm {
  border: 1px solid transparent !important;
}
.swal2-confirm:hover {
  border-color: var(--color-primary) !important;
  color: var(--color-primary) !important;
  background: none !important;
}
.swal2-cancel {
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}
.swal2-cancel:focus {
  box-shadow: none !important;
}
.swal2-cancel:hover {
  border-color: transparent !important;
  color: #fff !important;
  background: var(--color-primary) !important;
}
.change-password{
  text-align: center;
  width: fit-content;
  margin-bottom: 10px;
}
.change-password:hover{
  color: var(--color-primary);
}
.prof-container{
  position: relative;
}
