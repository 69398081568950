.tab-section-container {
  width: 100%;
  /* min-height: 300px; */
  background-color: var(--bg-gray);
  border-radius: var(--radius);
  padding: 20px 0;
}
.tab-section-container .rdt_Table{
  padding: 0 20px;
}

.profile-submit-section {
  display: flex;
}

.btn-submit {
  background-color: var(--color-primary);
  color: #fff;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 50px 50px 0;
  border: 1px solid transparent;
  text-transform: uppercase;
}

.btn-submit:hover {
  background-color: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.profile-container {
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 100%;
  padding: 30px;
  gap: 20px;
}

.menu-sec {
  position: sticky;
  top: 0;
  width: 100%;
}

.prof-body-container {
  display: grid;
  grid-template-rows: 50px 1fr;
  gap: 20px;
}

.prof-body {
  height: calc(100vh - 70px);
  overflow-y: auto;
  position: sticky;
  top: 0;
}


.navbar-container {
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.profile-container2 {

  display: grid;
  grid-template-columns: 175px 1fr;
  z-index: 100;
}

.tab-nav {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  z-index: 100;
  padding-bottom: 20px;
}

.edit-prof-header {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.edit-prof-header::-webkit-scrollbar {
  width: 0px;
}

.active-scroll-spy {
  background-color: yellowgreen;
  border-radius: 15px;
}

.revisions {
  margin: 20px 0;
  height: 250px;
  overflow-y: auto;
}

.revisions li::marker {
  font-size: 15px;
  color: var(--color-primary) !important;
}

.revisions li {
  font-size: 12px;
}

.logo {
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.tab-nav a {
  font-size: 18px;
  color: #000;
  background-color: var(--bg-gray);
  border-radius: 25px;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s;

}

.menu-tab {
  padding: 0 12px;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  height: 40px;
  text-transform: capitalize;
}

.tab-nav a:hover,
.tab-nav a.active {
  background-color: var(--color-primary-light) !important;
  color: var(--color-primary);
}

.tab-nav a:hover button,
.tab-nav a.active button {
  color: var(--color-primary);
}

.section-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 0 15px;
}

.section-container section {
  font-size: 24px;
  font-weight: 700;
  scroll-margin: 120px;
}

/* personal section  */
.prof-container {
  position: relative;
  margin: 0;
}

.prof-edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--bg-gray);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.personal-sec {
  background-color: var(--bg-gray);
  padding: 20px;
  border-radius: var(--radius);
}

.profile-body {
  background-color: var(--bg-gray);
  border-radius: var(--radius) var(--radius) 0 0;

}

.profile-header {
  position: sticky;
  width: 100%;
  top: 55px;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  white-space: nowrap;
  z-index: 999;
}

.user-name {
  display: flex;
  align-items: center;
  width: 100%;

}

.username {
  font-size: 24px;
  color: var(--color-primary);
  margin: 0 15px 0 20px;
  font-weight: bold;
}

.header-join-exp {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}

.pro-right-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.status-box,
.rivisions {
  width: 100%;
  height: 300px;
  border-radius: var(--radius);
  background-color: var(--bg-gray);
  padding: 20px;
}

.status-box {
  background-color: var(--bg-status-box);
}

/* personal information style  */
.font-arabic-field input{
  font-family: 'Kanz-al-Marjaan' !important;
}
.gender-check {
  height: 42px;
  width: 100%;
  padding-left: 30px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
  cursor: pointer;
}

.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: none;
}

.label {
  color: #898989;
  margin-left: 12px;
}

/* madal Style  */
.section-modal .modal-content {
  background-color: var(--bg-gray);
  border-radius: var(--radius);
  padding: 30px;
}

.modal-open .container-fluid,
.modal-open .container {
  -webkit-filter: blur(15px) grayscale(90%);
  filter: blur(1px);
}

.section-modal {
  backdrop-filter: blur(5px) !important;
  z-index: 99999;
  background-color: #00000008 !important;
}

.modal-dropdown {
  height: 40px;
  border-radius: var(--radius);
  padding: 0 16px 0 12px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-gray-color);
  font-weight: 700;
}

.modal-dropdown::after {
  display: none;
}

.dropdown-menu {
  transform: translate(5%, 50px);
  width: 90%;
  font-size: 13px;
  max-height: 200px;
  overflow-y: auto;
  min-width: 100%;
  width: fit-content;
}

.dropdown-item {
  padding: 8px 12px;

}

.dropdown-item:hover {
  background-color: var(--color-primary);
  color: #fff;

}

.selected-item {
  background-color: var(--color-primary);
  color: #fff;
}

.section-name {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--color-primary);

}

.preview {
  position: relative;
}
.show-preview{
  height: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.5s;
}

.preview:hover .show-preview {
  transition: all 0.5s;
  width: 100%;
  height: 30px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.personal-sec .family-cart-wrapper {
  z-index: 0 ;
}
