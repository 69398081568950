.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;
}
.login-outer {
  width: 800px;
  min-height: 550px;
  height: auto;
  background-color: var(--bg-gray);
  border-radius: 30px;
  margin: 100px auto;
  position: relative;
}
.copyright {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--black);
}
.login-inner {
  width: 330px;
  height: auto;
  margin: 50px auto;
}
.login-logo {
  text-align: center;
  margin-bottom: 20px;
}
.login-logo img {
  height: 150px;
  width: 150px;
}
.input-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}
.forget-pass {
  text-align: center;
  margin-top: 20px;
}
.forget-pass a {
  color: #000;
  text-decoration: underline !important;
}
