.loading-logo {
    position: relative;
background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-logo svg {
    opacity: 1;
    animation: 3s ease-in-out 1s infinite pulse;
    z-index: 5000;
}
span.sub_title_content {
    background: #f8f8f8;
    margin-right: 5px;
    padding: 4px 12px;
    border-radius: 50px;
    color: #000;
    font-size: 12px;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}