.family-cart-wrapper{
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: var(--radius);
    padding: 20px;
    gap: 20px;
}
.card-title{
    position: absolute;
    top: -15px;
    width: 100px;
    background-color: var(--color-primary);
    color: #fff;
    height: 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.family-info{
    width: 100%;
}
.its_age{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.info-group{
    display: flex;
    align-items: center;
    gap: 2px;
}
.info-group-name{
    display: flex;
    align-items: flex-start;
    gap: 2px;
}
.family-cart-wrapper .f-label{
   color: var(--color-primary);
}